<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4 class="mb-5">
                    Linked question
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{lesson && lesson.product_name ? lesson.product_name : null}} \ {{lesson && lesson.name ? lesson.name : null}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-center mb-3">
              <div class="col-md-7">
                <!-- Autocomplete component for selecting a question -->
                <v-autocomplete v-model="questionnaireId"
                    dense :items="questionnaires" item-text="question" item-value="id"
                    outlined :search-input.sync="questionSearch"
                    label="Select a Question"
                    placeholder="Choose a question from the question list">
                  <template v-slot:item="{ item }">
                    <p style="font-size: 1.1em; line-height: 1.3; color: #333;  word-wrap: break-word; white-space: normal;
                          margin: 0; " v-html="getLimitedText(item.question,20)">
                    </p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <p style="font-size: 1.1em; line-height: 1.3; color: #333;  word-wrap: break-word; white-space: normal;
                          margin: 0; " v-html="getLimitedText(item.question,10)">
                    </p>
                  </template>
                </v-autocomplete>
              </div>
              <div class="col-auto">
                <!-- Button to add FAQ, aligned with autocomplete -->
                <v-btn
                    @click="linkQuestion()"
                    color="primary"
                    dark
                    rounded
                    elevation="2"
                    class="ml-2 mt-1"
                >
                  <v-icon small class="mr-1">fas fa-plus</v-icon>
                  Add survey questions
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>
              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="! loading">
                <thead>
                    <tr>
                      <th class="px-3"></th>
                      <th class="px-3">Question</th>
                      <th class="text-left">Linked Date</th>
                      <th class="text-left">Actions</th>
                    </tr>
                </thead>
                <template>
                  <draggable class="w-100" v-model="linked_questionnaires" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(linked_questionnaire, index) in linked_questionnaires" :key="index">

                      <td  class="px-3">
                        <i class="fa fa-sort"></i>
                      </td>
                      <td  class="px-3">
                       <span style="font-size: 1.1em; line-height: 1.6; color: #333;  word-wrap: break-word; white-space: normal;
                              margin: 0; " class="" v-html="getLimitedText(linked_questionnaire.question, 50)">

                        </span>
                      </td>
                      <td>{{ linked_questionnaire.linked_date }}</td>
                      <v-col cols="3" class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">


                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="unlinkQuestion(linked_questionnaire)">
                                    <span class="navi-icon">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                  <span class="navi-text">Unlink</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </v-col>
                    </tr>
                    <tr v-if="linked_questionnaires.length === 0">
                      <td class="text-center px-3" colspan="8">No items found.</td>
                    </tr>
                  </draggable>
                </template>
              </table>
            </div>
          </div>
          <v-overlay :value="isSorting">
            <v-progress-circular
                indeterminate
                color="dark"
            ></v-progress-circular>
          </v-overlay>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import QuestionnaireService from "@/services/questionnaire/QuestionnaireService";
import LessonService from "@/services/product/lesson/LessonService";
import draggable from 'vuedraggable';

const lesson=new LessonService();
const questionnaire=new QuestionnaireService();
export default {
  components: {draggable},
  data(){
    return{
      loading:false,
      questionnaires:[],
      questionLoading:false,
      questionnaireId:null,
      linked_questionnaires:[],
      isSorting:false,
      lesson:{},
      questionSearch:null,
    }
  },
  watch:{
    questionSearch(val) {
      this.getAllQuestionnaires(val)
    },
  },
  methods:{
    getLessonDetail(){
      lesson
          .show(this.lessonId)
          .then((res) => {
            this.lesson=res.data.lesson;
          })
          .catch((err) => {


          })
          .finally(() => {

          })
    },
    unlinkQuestion(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {

            lesson
                .unlinkQuestion(item.lesson_id,{'questionnaire_id':item.questionnaire_id})
                .then((response) => {
                  this.getAllLinkedQuestionnaires();
                  this.$snotify.success("Questionnaire unlinked successfully");
                })
                .catch((err) => {
                });
          }
        },
      });
    },
    resetData(){
      this.questionnaireId=null;
    },
    getLimitedText(text, wordLimit) {
      let words = text.split(" ");
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + " ...";
      }
      return text;
    },
    linkQuestion(){
      this.loading=true;
      lesson
          .linkQuestion(this.lessonId,{'questionnaireId':this.questionnaireId})
          .then((res) => {
              this.resetData();
              this.$snotify.success('Questionnaire linked to lesson succesfully!!');
              this.getAllLinkedQuestionnaires();
          })
          .catch((err) => {
            if(err.errors){
              this.$snotify.error(err.errors);
            }else{
              this.$snotify.error('Something went wrong');
            }
            this.questionnaireId=null;

          })
          .finally(() => {
            this.loading=false;
          })
    },
    getAllQuestionnaires(search){

      this.questionLoading=true;
      questionnaire
          .getAllQuestionnaireForLesson(this.lessonId,{'product_id':this.productId,'info': search})
          .then((res) => {
            this.questionnaires=res.data.questionnaires;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.questionLoading=false;
          })
    },
    sort() {
      this.isSorting=true;
      lesson
          .sortLessonQuestionnaire(this.lessonId,this.linked_questionnaires)
          .then(response => {
            this.$snotify.success('Questionnaire Sorted!!');
          })
          .catch(err => {
            this.$snotify.error('Problem occured!!');
          })
          .finally(() => {
            this.isSorting = false;
          })
    },
    getAllLinkedQuestionnaires(){
      this.loading=true;
      lesson
          .getAllLinkedQuestionnaires(this.lessonId)
          .then((res) => {
            this.linked_questionnaires=res.data.linkedQuestionnaires;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading=false;
          })
    },
  },
  mounted() {
    this.getLessonDetail();
    this.getAllQuestionnaires();
    this.getAllLinkedQuestionnaires();
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    lessonId() {
      return this.$route.params.lessonId;
    }
  },
}
</script>